import React, { Component } from 'react';
import Home from './pages/home/home.component';
import Distribution from './pages/distribution/distribution.component';
import WineClub from './pages/wine-club/wine-club.component';
import Contact from './pages/contact/contact.component';
import { Switch, Route } from 'react-router-dom';
import MainNav from './components/main-nav.component';
// import logo from './logo.svg';
import './App.scss';
import { trackPromise } from 'react-promise-tracker';
class App extends Component {
  constructor() {
    super();
    this.state = {
      homeText: '',
      homeSecondText: '',
      contactText: '',
      wineClubText: '',
      distributionText: '',
      currentPage: '',
      isLoading: true,
      over19: false
    }
  }
  populateFields(res){
    let homeText = (res.find(x => x.title === 'home-main')) ? res.find(x => x.title === 'home-main').body : "<h1>page under maintenance<h2>";
    let homeSecondText = (res.find(x => x.title === 'home-second')) ? res.find(x => x.title === 'home-second').body : "";
    let contactText = (res.find(x => x.title === 'contact-main')) ? res.find(x => x.title === 'contact-main').body : "<h1>page under maintenance<h2>";
    let wineClubText = (res.find(x => x.title === 'wine-club-main')) ? res.find(x => x.title === 'wine-club-main').body : "<h1>page under maintenance<h2>";
    let distributionText = (res.find(x => x.title === 'distribution-main')) ? res.find(x => x.title === 'distribution-main').body : "<h1>page under maintenance<h2>";
    return { homeText: homeText, homeSecondText: homeSecondText, contactText: contactText, wineClubText: wineClubText, distributionText: distributionText}
  }

  componentDidMount() {
    trackPromise(
      fetch('https://usc-vms.herokuapp.com/websites/banqpay')
        .then(response => response.json())
        .then(res => this.setState(this.populateFields(res) ))
        .then(this.setState({isLoading: false}))
    )
   };
  // handleChange = (e) => {
  //   this.setState( { searchField: e.target.value } )
  // }

  render() {
    return (
      <div>
        <MainNav currentPage={this.state.currentPage}/>
        {this.state.isLoading ? <div className="loading"><div className="lds-dual-ring"></div></div> : null}
        <Switch>
          <Route exact path='/' render = {(props) => <Home homeText={this.state.homeText} homeSecondText={this.state.homeSecondText}/>}/>
          <Route path='/distribution' render = {(props) => <Distribution mainText={this.state.distributionText}/>} />
          <Route path='/wine-club' render = {(props) => <WineClub mainText={this.state.wineClubText}/>} />
          <Route path='/contact' render = {(props) => <Contact mainText={this.state.contactText}/>} />
        </Switch>
      </div>
    );
  }
}
export default App;
