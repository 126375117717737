import React from 'react';
import './main-nav.styles.scss';
import { Link} from 'react-router-dom'

// functional component

const MainNav = (props) => (
  <nav className="navbar navbar-default">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
        <a className="navbar-brand" href="/">
          <img src="https://banqpay.s3.amazonaws.com/bqp_b.svg" alt="BanQpay Logo"/>
        </a>
      </div>
      <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        <ul className="nav navbar-nav navbar-right pull-right">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/distribution">Distribution</Link></li>
          <li><Link to="/wine-club">Wine Club</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
  </nav>
);
export default MainNav;
