import React from 'react';
// import ResourceGroupList from'../../components/resource-group-list/resource-group-list.component'
import './home.styles.scss';
// const classes = useStyles();
function getText(text) {
  return {__html: text};
}

const Home = (props) => (
  <div className='home-wrap'>
    <div dangerouslySetInnerHTML={getText(props.homeText)} />
    <div dangerouslySetInnerHTML={getText(props.homeSecondText)} />
  </div>
);
export default Home;
