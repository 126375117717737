import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import { usePromiseTracker } from "react-promise-tracker";
import * as serviceWorker from './serviceWorker';

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();
 return (
   promiseInProgress &&
    <div className="loading"><div className="lds-dual-ring"></div></div>
  );
}

ReactDOM.render((
  <BrowserRouter>
    <App />
    <LoadingIndicator/>
  </BrowserRouter>
), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
