import React from 'react';
// import ResourceGroupList from'../../components/resource-group-list/resource-group-list.component'
import './wine-club.styles.scss';
// const classes = useStyles();
function getText(text) {
  return {__html: text};
}

const WineClub = (props) => (
  <div className='wc-wrap'>
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-xs-12 col-sm-12'>
          <div dangerouslySetInnerHTML={getText(props.mainText)} />
        </div>
      </div>
    </div>
  </div>
);
export default WineClub;
